import React, {useEffect} from 'react';
import st from "./Contacts.module.css";
import {Link, useHistory} from "react-router-dom";
import {ReactComponent as Phone} from "../../image/icons/phone.svg";
import {ReactComponent as Whatsapp} from "../../image/whatsapp.svg";
import {ReactComponent as Instagram} from "../../image/instagram.svg";
import {ReactComponent as Mail} from "../../image/icons/mail.svg";
import {ReactComponent as WorkTime} from "../../image/icons/workTime.svg";
import {ReactComponent as MapIcon} from "../../image/icons/project/map.svg";

import cn from "classnames";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import {useDispatch, useSelector} from "react-redux";
import {fetchCoordinates} from "../../redux/slices/coordinates";

const AboutCompany = () => {
    const dispatch = useDispatch();
    const {coordinates} = useSelector(state => state.coordinates)
    const isCoordinatesLoading = coordinates.status === "loading";
    const history = useHistory();

    useEffect(() => {
        if (coordinates.items.length === 0)
            dispatch(fetchCoordinates())
    }, [dispatch])

    return (
        <div className={st.fullContainer}>
            <div className={st.container}>
                <div className={st.contactBlock}>
                    <span className={st.title}>Контакты</span>
                    <div className={st.contact}>
                        <Phone className={st.iconStroke}/>
                        <a href={"tel:89264795728"}>+7(926)479-57-28</a>
                    </div>
                    <div className={st.contact}>
                        <Whatsapp className={st.iconFill}/>
                        <a target="_blank" href='https://wa.me/79264795728?text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%21'><span>Написать нам</span></a>
                    </div>
                    {/*<a target={"_blank"} href={"https://instagram.com/riku.remont?igshid=OGQ5ZDc2ODk2ZA=="}*/}
                    {/*   className={st.contact}>*/}
                    {/*    <Instagram className={st.iconFill}/>*/}
                    {/*    <span>Мы в instagram</span>*/}
                    {/*</a>*/}
                    <div className={st.contact}>
                        <Mail className={st.iconFill}/>
                        <span>riku.general@yandex.ru</span>
                    </div>
                    <div className={st.contact}>
                        <WorkTime className={st.iconStroke}/>
                        <span>Пн-Пт 9:00-19:00, Сб-Вс выходные</span>
                    </div>
                    <div className={st.contact}>
                        <MapIcon className={st.iconStroke}/>
                        <span>Г. Москва, улица Куусинена, 15к1</span>
                    </div>
                </div>
                <div className={cn(st.mapBlock)}>
                    <YMaps>
                        <Map
                            defaultState={{
                                center: [55.755814, 37.617635], // Координаты по умолчанию
                                zoom: 10, // Масштаб по умолчанию
                            }}
                            className={st.map}
                        >
                            {(isCoordinatesLoading ? [] : coordinates.items).map((coordinates) =>
                                <Placemark
                                    key={coordinates.id}
                                    geometry={[coordinates.latitude, coordinates.longitude]} // Координаты адреса
                                    modules={['geoObject.addon.balloon', 'geoObject.addon.hint']}
                                    properties={{
                                        hintContent: coordinates.hoverInfo,
                                        balloonContent: coordinates.info, // Дополнительная информация для всплывающей подсказки
                                    }}
                                    options={{
                                        preset: "islands#icon",
                                        iconColor: coordinates.projectId !== null ? "#ff0000" : "#0059ff", // Цвет метки
                                    }}
                                    onClick={() => {
                                        if (coordinates.projectId !== null)
                                            history.push(`/projects/${coordinates.projectId}`);
                                    }}
                                ></Placemark>
                            )}
                        </Map>
                    </YMaps>
                </div>
               

            </div>
        </div>
    );
};

export default AboutCompany;